import React from "react"
import Container from "../components/container"
import footerStyles from "./footer.module.scss"

export default ({ children }) => (

  <Container>
        <footer className={footerStyles.footer}>
            © 2019 Paul Brough
        </footer>
  </Container>
)